import React from 'react';
import Layout from "../components/layout"
import { Card, Container, ListGroup } from "react-bootstrap"

export default function privacy() {
    return (
      <Layout>
        <Container>
          <Card>
            <Card.Body className="temsBody">
              <Card.Title className="termsTitle">Please read privacy and cookies policy sections</Card.Title>
              <br></br>
              <Card.Title className="termsTitle">Privacy Policy</Card.Title>
              <br></br>
              <Card.Subtitle className="termsSubTitle">
                Welcome to LOGOPWA!
              </Card.Subtitle>
              <br></br>
              <Card.Text className="termsText">
                LOGOPWA is owned and operated by LOGOPWA, INC.<br></br>
                <br></br>
                LOGOPWA values your privacy. This notice describes what
                information we collect, how we use it, and, if necessary, those
                with whom we share it.<br></br>
                <br></br>
                By using the LOGOPWA services, you are accepting the practices
                described in this Privacy Policy. Your use of the Website is
                also subject to our Terms and Conditions.<br></br>
                <br></br>
                This Privacy Policy may change from time to time. Your continued
                use of the website after we make changes is deemed to be
                acceptance of those changes, so please check the policy
                periodically for updates. This Privacy Policy has been developed
                and is maintained in accordance with all applicable federal and
                international laws and regulations, and specifically with The
                General Data Protection Regulation (GDPR - European regulation)
                and The California Online Privacy Protection Act (CalOPPA – U.S
                regulation).
              </Card.Text>
              <Card.Subtitle className="termsSubTitle">
                GENERAL INFORMATION
              </Card.Subtitle>
              <br></br>
              <Card.Text className="termsText">
                The personal data of the users that are collected and processed
                through the website:
                <a href="https://www.logopwa.com" target="_blank">
                  https://www.logopwa.com
                </a>
                <br></br>
                Will be under responsibility and in charge of:<br></br>•
                LOGOPWA, INC. <br></br>• info@logopwa.com<br></br>
                (Hereinafter referred to as “LOGOPWA”).
              </Card.Text>
              <Card.Subtitle className="termsSubTitle">
                TYPES OF INFORMATION GATHERED
              </Card.Subtitle>
              <br></br>
              <Card.Text className="termsText">
                The information we learn from customers helps personalize and
                continually improve your experience at LOGOPWA. Here are the
                types of information we gather:<br></br>
                <br></br>
                Information You Give Us. You provide information when you
                search, read and watch the content, purchase a service or
                communicate with us. As a result of those actions, you might
                supply us with the following information:<br></br>• First and
                last name<br></br>• Email address<br></br>• Phone number
                (includes WhatsApp)<br></br>• IP address<br></br>• Any
                additional information relating to you that you provide to us
                directly through our websites or indirectly through our websites
                or online presence such as ‘cookies’.<br></br>
                <br></br>
                LOGOPWA will not collect any personally identifiable information
                about you, unless you provide it.<br></br>
                <br></br>
                <strong>GOOGLE Analytics.</strong> We use Google Analytics
                provided by Google, Inc., USA (“Google”). These tool and
                technologies collect and analyze certain types of information,
                including IP addresses, device and software identifiers,
                referring and exit URLs, feature use metrics and statistics,
                usage and purchase history, media access control address (MAC
                Address), mobile unique device identifiers, and other similar
                information via the use of cookies. The information generated by
                Google Analytics (including your IP address) may be transmitted
                to and stored by Google on servers in the United States. We use
                the GOOGLE Analytics collection of data to enhance the website
                and platform and improve our service.<br></br>
                <br></br>
                <strong>Automatic Information.</strong>Like many websites, we
                use "cookies." We obtain certain types of anonymous information
                which is not personally identifiable when your web browser
                accesses LOGOPWA or third-party content served by or on behalf
                of LOGOPWA on other websites. Some of the information we collect
                and analyze includes the Internet protocol (IP) address;
                computer and connection information such as browser type,
                version, and connection speed; purchase history; and the content
                you searched for, viewed, and possibly purchased.
              </Card.Text>
              <Card.Subtitle className="termsSubTitle">
                HOW LONG WE KEEP YOUR DATA
              </Card.Subtitle>
              <br></br>
              <Card.Text className="termsText">
                Personal data will be kept for the time necessary for the
                provision of the services or as long as the interested party
                does not withdraw their consent. The user's personal data will
                be deleted from our database immediately.
              </Card.Text>
              <Card.Subtitle className="termsSubTitle">
                HOW WE USE YOUR INFORMATION.
              </Card.Subtitle>
              <br></br>
              <Card.Text className="termsText">
                In general, we use the information we collect primarily to
                provide, maintain, protect and improve our current website and
                services and to develop new services. We use personal
                information collected through our site as described below and
                described elsewhere in this Policy to:<br></br>• Identify you as
                a user in our system.<br></br>• Provide the services.<br></br>•
                Schedule your consultations.<br></br>• Improve our services,
                website, and how we operate our business.<br></br>• Understand
                and enhance your experience using our website and services.
                <br></br>• Send emails through bulk email delivery platforms
                such as MailChimp and Klaviyo.<br></br>• Communicate with you
                through our support team.<br></br>• Send you related
                information, including confirmations, invoices, technical
                notices, updates, security alerts and support and administrative
                messages.<br></br>• Communicate with you about upcoming events
                and news about products and services offered by LOGOPWA and our
                selected partners.<br></br>• Marketing purposes of LOGOPWA
                (Facebook ads and Google ads)<br></br>• Link or combine your
                information with other information we get from third parties to
                help understand your needs and provide you with better service.
                <br></br>• Protect, investigate and deter against fraudulent,
                unauthorized or illegal activity.
              </Card.Text>
              <Card.Subtitle className="termsSubTitle">
                HOW DO YOU GET MY CONSENT?
              </Card.Subtitle>
              <Card.Text className="termsText">
                By purchasing a service and providing us with personal
                information to communicate with you, you agree that we collect
                and use your information. You can withdraw your consent, contact
                us through the contact information or the contact page.
              </Card.Text>
              <Card.Subtitle className="termsSubTitle">
                HOW WE SHARE INFORMATION
              </Card.Subtitle>
              <Card.Text className="termsText">
                Information about our customers is an important part of our
                business, and we are not in the business of selling it to
                others. We share customer information only as described below.
                <br></br>
                <br></br>
                <strong>Third-Party Service Providers. </strong>We employ other
                companies and individuals to perform functions on our behalf.
                Examples include process payments, sending email, analyzing
                data, providing marketing assistance, providing search results
                and links. They have access to personal information needed to
                perform their functions, but may not use it for other purposes.
                <br></br>
                <br></br>
                <strong>Business Transfers. </strong>In the event that LOGOPWA
                creates, merges with, or is acquired by another entity, your
                information will most likely be transferred. LOGOPWA will email
                you or place a prominent notice on our Website before your
                information becomes subject to another privacy policy.<br></br>
                <br></br>
                <strong>Protection of LOGOPWA and others.</strong> We release
                personal information when we believe release is appropriate to
                comply with the law, enforce or apply our Terms and conditions
                and other agreements, or protect the rights, property, or safety
                of LOGOPWA, our users or others. This includes exchanging
                information with other companies and organizations for fraud
                protection and credit risk reduction.
                <br></br>
                <br></br>
                <strong>With Your Consent. </strong>Other than as set out above,
                you will receive notice when personally identifiable information
                about you might go to third parties, and you will have an
                opportunity to choose not to share the information.<br></br>
                <br></br>
                <strong>Anonymous Information. </strong> LOGOPWA uses the
                anonymous browsing information collected automatically by our
                servers primarily to help us administer and improve the platform
                and website. We may also use aggregated anonymous information to
                provide information about the website to potential business
                partners and other unaffiliated entities. This information is
                not personally identifiable.
                <br></br>
                <br></br>
                <strong>Email Address. </strong>The email address that you
                supply to us for purposes of receiving our email communications
                will never be rented or sold to a third party.
              </Card.Text>
              <Card.Subtitle className="termsSubTitle">
                PROTECTING YOUR INFORMATION
              </Card.Subtitle>
              <br></br>
              <Card.Text className="termsText">
                We work to protect the security of your information during
                transmission by using Secure Sockets Layer (SSL) software, which
                encrypts information you input. If transactions are processed on
                the Website, transaction information is transmitted to and from
                the Website in encrypted form using industry-standard SSL
                connections to help protect such information from interception.
                We restrict authorized access to your personal information to
                those persons who have a legitimate purpose to know that
                information to provide products or services to you and those
                persons you have authorized to have access to such information.
                <br></br>
                <br></br>
                LOGOPWA follows generally accepted industry standards to protect
                the personal information submitted to us, both during
                transmission and once LOGOPWA receives it. No method of
                transmission over the Internet, or method of electronic storage,
                is 100% secure. Therefore, while LOGOPWA strives to use
                commercially acceptable means to protect your personal
                information, we cannot guarantee its absolute security.<br></br>
                <br></br>
                We will not sell, distribute, or lease your personal information
                to third parties unless we have your permission or are required
                by law to do so.
              </Card.Text>
              <Card.Subtitle className="termsSubTitle">
                YOUR RIGHTS
              </Card.Subtitle>
              <Card.Text className="termsText">
                You have the following rights in relation to your personal data
                provided to LOGOPWA:
                <br></br>• Access your personal data.<br></br>• Request the
                correction of inaccurate data.<br></br>• Request its deletion.
                <br></br>• Request limitation of the processing of your data.
                <br></br>• Oppose the treatment of your data.<br></br>• Request
                your portability.<br></br>• Not be subject to automated
                individual decisions.<br></br>
                <br></br>The Users can exercise all these rights contacting us
                through the contact information or the contact page and
                indicating the reason for his request.<br></br>
                <br></br>Without preconception to any other administrative
                appeal or legal action, the User shall have the right to submit
                a claim to a Control Authority, in particular in the Member
                State in which he or she has their habitual residence, place of
                work or place of the alleged infringement. If you consider that
                the processing of your personal data is not appropriate to the
                regulations, as well as in the case of not seeing the exercise
                of your rights satisfied. The control authority in which the
                claim was filed will inform the claimant about the course and
                result of the claim.
              </Card.Text>
              <Card.Subtitle className="termsSubTitle">
                EU MEMBERS’ RIGHTS (GDPR COMPLIANT)
              </Card.Subtitle>
              <Card.Text className="termsText">
                If you are habitually located in the European Union, you have
                the right to access, rectify, download or erase your
                information, as well as the right to restrict and object to
                certain processing of your information. While some of these
                rights apply generally, certain rights apply only in certain
                limited circumstances. We describe these rights below:<br></br>
                <br></br>• Access and Porting: You can access your information
                at any time.
                <br></br>
                <br></br>• Rectify, Restrict, Limit, Delete: You can also
                rectify, restrict, limit or delete much of your information by
                contact us.<br></br>
                <br></br>• Object: Where we process your information based on
                our legitimate interests explained above, or in the public
                interest, you can object to this processing in certain
                circumstances. In such cases, we will cease processing your
                information unless we have compelling legitimate grounds to
                continue processing or where it is needed for legal reasons.
                <br></br>
                <br></br>• Revoke consent: Where you have previously provided
                your consent, such as to permit us to process data about you,
                you have the right to withdraw your consent to the processing of
                your information at any time. For example, you can withdraw your
                consent by updating your settings. In certain cases, we may
                continue to process your information after you have withdrawn
                consent if we have a legal basis to do so or if your withdrawal
                of consent was limited to certain processing activities.
                <br></br>
                <br></br>• Complain: Should you wish to raise a concern about
                our use of your information (and without prejudice to any other
                rights you may have), you have the right to do so with your
                local supervisory authority.<br></br>
                <br></br>Additionally, if you are a European resident, we note
                that we are processing your information in order to fulfil
                contracts we might have with you or otherwise to pursue our
                legitimate business interests listed above. Additionally, please
                note that your information will be transferred outside of
                Europe. You can see more about these rights
                at:https://ec.europa.eu/info/law/law-topic/data-protection/reform/rights-citizens_en
              </Card.Text>
              <Card.Subtitle className="termsSubTitle">
                CALIFORNIA ONLINE PRIVACY PROTECTION ACT COMPLIANCE (CalOPPA)
              </Card.Subtitle>
              <Card.Text className="termsText">
                CalOPPA is the first state law in the nation to require
                commercial websites and online services to post a privacy
                policy. The law’s reach stretches well beyond California to
                require any person or company in the United States (and
                conceivably the world) that operates websites collecting
                Personally Identifiable Information from California consumers to
                post a conspicuous privacy policy on its website stating exactly
                the information being collected and those individuals or
                companies with whom it is being shared.<br></br>
                <br></br>
                Because we value your privacy, we have taken the necessary
                precautions to be in compliance with the California Online
                Privacy Protection Act (CalOPPA).<br></br>
                According to the California Online Privacy act, you have the
                following rights:<br></br>• Know what personal data is being
                collected.<br></br>• Know whether your personal data is sold or
                disclosed and to whom.<br></br>• Access to your personal data.
                <br></br>• Request to delete any personal information about you.
                <br></br>• Not be discriminated against for exercising their
                privacy rights.
              </Card.Text>
              <Card.Subtitle className="termsSubTitle">
                CHILDREN’S ONLINE PRIVACY PROTECTION
              </Card.Subtitle>
              <Card.Text className="termsText">
                We are in compliance with the requirements of the GDPR (General
                Data Protection Regulation), and the California Online Privacy
                Protection Act (CalOPPA) regarding the protection of the
                personal data of minors. We do not collect any information from
                anyone under 13 years of age. Our website, platform and services
                are all directed to people who are at least 18 years old or
                older.
              </Card.Text>
              <Card.Subtitle className="termsSubTitle">
                EDITING AND DELETING INFORMATION
              </Card.Subtitle>
              <Card.Text className="termsText">
                If you believe that any information, we are holding on you is
                incorrect or incomplete, please write to or email us as soon as
                possible. We will promptly correct any information found to be
                incorrect. You can change, modify, rectify and delete your
                Information at any time, please contact us through the contact
                information.<br></br>
                <br></br>To opt-out of LOGOPWA email, follow the instructions
                included in the email. Your request should be processed within
                48 hours.
              </Card.Text>
              <Card.Subtitle className="termsSubTitle">
                SUPPLIERS AND OTHER THIRD PARTIES
              </Card.Subtitle>
              <Card.Text className="termsText">
                Except as otherwise expressly included in this Privacy Policy,
                this document addresses only the use and disclosure of
                information LOGOPWA collects from you. If you disclose your
                information to others, whether other users or suppliers on
                LOGOPWA, different rules may apply to their use or disclosure of
                the information you disclose to them. LOGOPWA does not control
                the privacy policies of third parties, and you are subject to
                the privacy policies of those third parties where applicable.
                LOGOPWA is not responsible for the privacy or security practices
                of other websites on the Internet, even those linked to or from
                the LOGOPWA site. LOGOPWA encourages you to ask questions before
                you disclose your personal information to others.
              </Card.Text>
              <Card.Subtitle className="termsSubTitle">
                CHANGES TO THIS PRIVACY POLICY
              </Card.Subtitle>
              <Card.Text className="termsText">
                LOGOPWA will post modifications by date to this Privacy Policy
                to inform you of any changes. LOGOPWA reserves the right to
                modify this Privacy Policy at any time, please review it
                frequently.
              </Card.Text>
              <Card.Subtitle className="termsSubTitle">
                ENFORCEMENT
              </Card.Subtitle>
              <Card.Text className="termsText">
                LOGOPWA regularly reviews its compliance with this Policy.
                Please feel free to direct any questions or concerns regarding
                this Policy or LOGOPWA treatment of personal information by
                contacting us.<br></br>
                <br></br>When we receive formal written complaints, it is
                LOGOPWA policy to contact the complaining user regarding his or
                her concerns. We will cooperate with the appropriate regulatory
                authorities, including local data protection authorities, to
                resolve any complaints regarding the transfer of personal data
                that cannot be resolved between LOGOPWA and an individual.
              </Card.Text>
              <Card.Title className="termsTitle">COOKIE POLICY</Card.Title>
              <Card.Text className="termsText">
                This Cookie Policy explains how and why cookies and other
                similar technologies may be stored on and accessed from your
                device when you use or visit:
                <a href="https://www.logopwa.com" target="_blank">
                  https://www.logopwa.com
                </a>
                <br></br>
                <br></br>This Cookie Policy should be read together with the
                following:<br></br>
                <br></br>
                <a href="https://www.logopwa.com/privacy" target="_blank">
                  • Privacy Policy
                </a>
                <br></br>
                <br></br>
                <a href="https://www.logopwa.com/terms" target="_blank">
                  • Terms and Conditions.
                </a>
                <br></br>
                <br></br>By using LOGOPWA website, you accept the use of cookies
                by this website, in the terms contained in this policy.
              </Card.Text>
              <Card.Subtitle className="termsSubTitle">
                WHAT ARE COOKIES?
              </Card.Subtitle>
              <Card.Text className="termsText">
                Cookies" are small text files that are stored on your computer
                or mobile device when you visit a website. They allow the
                website to recognize your device and remember if you have been
                to the website before.<br></br>
                <br></br>Cookies are a very common web technology; most websites
                use cookies and have done so for years. Cookies are widely used
                to make the website work more efficiently.<br></br>
                <br></br>Cookies are used to measure which parts of the website
                users visit and to personalize their experience. Cookies also
                provide information that helps us monitor and improve the
                performance of the website.
              </Card.Text>
              <Card.Subtitle className="termsSubTitle">
                REFUSING OR WITHDRAWING CONSENT TO THE USE OF COOKIES
              </Card.Subtitle>
              <Card.Text className="termsText">
                If you do not want Cookies to be dropped on your device, you can
                adjust the setting of your Internet browser to reject the
                setting of all or some Cookies and to alert you when a Cookie is
                placed on your device. For further information about how to do
                so, please refer to your browser ‘help’, ‘tool’, or ‘edit’
                section. Please note that if you use your browser settings to
                block all Cookies, including strictly necessary Cookies, you may
                not be able to access or use all or parts of the functionalities
                of LOGOPWA.<br></br>
                <br></br>If you want to remove previously-stored Cookies, you
                can manually delete the Cookies at any time. However, this will
                not prevent LOGOPWA website from placing further Cookies on your
                device unless and until you adjust your Internet browser setting
                as described above.<br></br>
                <br></br>We provide the links for the management and blocking of
                cookies depending on the browser you use:<br></br>• Internet
                Explorer:
                http://windows.microsoft.com/es-xl/internet-explorer/delete-manage-cookies#ie=ie-10
                <br></br>• Firefox:
                https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox
                <br></br>• Chrome:
                https://support.google.com/chrome/answer/95647?hl=en<br></br>•
                Safari:
                https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac
              </Card.Text>
              <Card.Subtitle className="termsSubTitle">
                FIRST-PARTY COOKIES
              </Card.Subtitle>
              <Card.Text className="termsText">
                We use Cookies to enhance the performance of our website and
                personalize your online LOGOPWA experience.<br></br>
                <br></br>Cookies help us to collect information on how people
                use our website and which pages they visit. They enable us to
                monitor the number of visitors and to analyze website usage
                patterns and trends.<br></br>
                <br></br>We collect this information anonymously, so it does not
                identify anyone as an individual and no personal information is
                stored in our Cookies. We always use Cookie data in a
                responsible way.<br></br>
                <br></br>Our aim is to continually improve LOGOPWA online
                experience. Cookies help to make our website work better and
                more efficiently. However, you can stop Cookies from being
                downloaded to your device from LOGOPWA at any time by selecting
                the appropriate settings in your browser. Most browsers will
                tell you how to change your browser settings to notify you when
                a Cookie is being set or updated, or to restrict or block
                certain types or all Cookies.
              </Card.Text>
              <Card.Subtitle className="termsSubTitle">
                THIRD-PARTY COOKIES
              </Card.Subtitle>
              <Card.Text className="termsText">
                Third-party cookies may come from partners that provide
                functional web tools for our site, other website where we
                advertise and from social networks.
              </Card.Text>
              <Card.Subtitle className="termsSubTitle">
                PERSISTENT COOKIES
              </Card.Subtitle>
              <Card.Text className="termsText">
                We use persistent Cookies to improve your experience of using
                LOGOPWA. This includes recording your acceptance of our Cookie
                Policy to remove the cookie message which first appears when you
                use LOGOPWA. Persistent Cookies remain on your device until
                deleted manually or automatically.
              </Card.Text>
              <Card.Subtitle className="termsSubTitle">
                SESSION COOKIES
              </Card.Subtitle>
              <Card.Text className="termsText">
                Session Cookies are temporary and deleted from your machine when
                your web browser closes. We use session Cookies to help us track
                internet usage for improvement of our website.<br></br>
                <br></br>You may refuse to accept browser Cookies by activating
                the appropriate setting on your browser. However, if you select
                this setting you may be unable to access certain parts of
                LOGOPWA website. Unless you have adjusted your browser setting
                so that it will refuse Cookies, our system will issue Cookies
                when you direct your browser to LOGOPWA.<br></br>
                <br></br>The data collected by LOGOPWA and/or through Cookies
                that may be placed on your computer will not be kept for longer
                than is necessary to fulfil the purposes mentioned above.
              </Card.Text>
              <Card.Subtitle className="termsSubTitle">
                USES OF COOKIES
              </Card.Subtitle>
              <Card.Text className="termsText">
                Our Cookies are used for the following purposes:<br></br>
                <br></br>
                <strong>Strictly Necessary:</strong> These Cookies are essential
                for LOGOPWA to perform its basic
              </Card.Text>
              functions.<br></br>
              <br></br>
              <strong>Security:</strong> We use these Cookies to help identify
              and prevent potential security risks. functions.<br></br>
              <br></br>
              <strong>Analytics and Performance: </strong> Performance Cookies
              collect information on how users interact with our website,
              including what pages are visited most, as well as other analytical
              data. We use these details to improve how our website function and
              to understand how users interact with them.
              <br></br>
              <br></br>
              <strong>Advertising: </strong> These Cookies are used to display
              relevant advertising to visitors who use our services or visit
              website we host or provide, as well as to understand and report on
              the efficacy of ads served on our website. They track details such
              as the number of unique visitors, the number of times particular
              ads have been displayed, and the number of clicks the ads have
              received. They are also used to build user profiles, including
              showing you ads based on products you’ve viewed on our website.
              These are set by LOGOPWA and trusted third party networks and are
              generally persistent in nature.
              <br></br>
              <br></br>
              <strong>GOOGLE Analytics. </strong> We use Google Analytics
              provided by Google, Inc., USA (“Google”). These tool and
              technologies collect and analyze certain types of information,
              including IP addresses, device and software identifiers, referring
              and exit URLs, feature use metrics and statistics, usage and
              purchase history, media access control address (MAC Address),
              mobile unique device identifiers, and other similar information
              via the use of cookies. The information generated by Google
              Analytics (including your IP address) may be transmitted to and
              stored by Google on servers in the United States. We use the
              GOOGLE Analytics collection of data to enhance the website and
              platform and improve our service.
              <br></br>
              <br></br>
              <strong>Security:</strong> We use these Cookies to help identify
              and prevent potential security risks.
              <Card.Subtitle className="termsSubTitle">
                CONTACT US
              </Card.Subtitle>
              <Card.Text className="termsText">
                If you have questions or concerns about the following policies:
                <br></br>
                <a href="https://www.logopwa.com/privacy" target="_blank">
                  • Privacy Policy
                </a>
                <br></br>
                <a href="https://www.logopwa.com/privacy" target="_blank">
                  • Cookies Policy
                </a>
                <br></br>
                Our handling and security of your data, please contact us at:
                <br></br>info@logopwa.com
              </Card.Text>
            </Card.Body>
          </Card>
        </Container>
      </Layout>
    )
}
